#root {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

body {
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}